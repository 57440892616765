dmx.Component('leaflet-map', {

  initialData: {
    latitude: null,
    longitude: null,
    zoom: null,
  },

  attributes: {
    preferCanvas: {
      type: Boolean,
      default: false,
    },

    layerControl: {
      type: Boolean,
      default: false,
    },

    attributionControl: {
      type: Boolean,
      default: true,
    },

    zoomControl: {
      type: Boolean,
      default: true,
    },

    zoomControlPosition: {
      type: String,
      default: 'topleft',
      enum: ['topleft', 'topright', 'bottomleft', 'bottomright'],
    },

    scaleControl: {
      type: Boolean,
      default: false,
    },

    scaleControlPosition: {
      type: String,
      default: 'bottomleft',
      enum: ['topleft', 'topright', 'bottomleft', 'bottomright'],
    },

    scaleControlMetric: {
      type: Boolean,
      default: true,
    },

    scaleControlImperial: {
      type: Boolean,
      default: true,
    },

    // removed center, use latitude and longitude instead
    /* 
    center: {
      // https://leafletjs.com/reference.html#latlng
      // [latitude, longitude], {lat: latitude, lng: longitude}
      type: [Array, Object],
      default: undefined,
    },
    */

    latitude: {
      type: Number,
      default: undefined,
    },

    longitude: {
      type: Number,
      default: undefined,
    },

    zoom: {
      type: Number,
      default: undefined,
    },

    zoomDelta: {
      type: Number,
      default: 1,
    },

    keyboard: {
      type: Boolean,
      default: true,
    },

    scrollWheelZoom: {
      type: String,
      default: true,
      enum: ['true', 'false', 'center'],
    },

    doubleClickZoom: {
      type: String,
      default: 'true',
      enum: ['true', 'false', 'center'],
    },

    touchZoom: {
      type: String,
      default: undefined,
      enum: ['true', 'false', 'center'],
    },

    tileProvider: {
      type: String,
      default: 'OpenStreetMap',
    },

    enableClustering: {
      type: Boolean,
      default: false,
    },

    markers: {
      type: Array,
      default: undefined,
    },

    markerId: {
      type: String, // expression
      default: 'id',
    },

    markerLatitude: {
      type: String, // expression
      default: 'latitude',
    },

    markerLongitude: {
      type: String, // expression
      default: 'longitude',
    },

    markerTooltip: {
      type: String, // expression
      default: 'tooltip',
    },

    markerPopup: {
      type: String, // expression
      default: 'popup',
    },

    markerGroup: {
      type: String, // expression
      default: 'group',
    },

    markerDraggable: {
      type: String, // expression
      default: 'draggable',
    },

    markerIconUrl: {
      type: String, // expression
      default: 'iconUrl',
    },

    markerColor: {
      type: String, // expression
      default: 'color',
    },
  },

  methods: {
    setView (lat, lng, zoom) {
      // Sets the view of the map (geographical center and zoom) with the given animation options.
      // setView(<LatLng> center, <Number> zoom, <Zoom/pan options> options?)
      this.map.setView([+lat, +lng], zoom);
    },

    setZoom (zoom) {
      // Sets the zoom of the map.
      // setZoom(<Number> zoom, <Zoom/pan options> options?)
      this.map.setZoom(zoom);
    },

    zoomIn () {
      // Increases the zoom of the map by delta (zoomDelta by default).
      // zoomIn(<Number> delta?, <Zoom options> options?)
      this.map.zoomIn();
    },

    zoomOut () {
      // Decreases the zoom of the map by delta (zoomDelta by default).
      // zoomOut(<Number> delta?, <Zoom options> options?)
      this.map.zoomOut();
    },

    fitBounds (bounds) {
      // Sets a map view that contains the given geographical bounds with the maximum zoom level possible.
      // fitBounds(<LatLngBounds> bounds, <fitBounds options> options?)
      this.map.fitBounds(bounds);
    },

    fitWorld () {
      // Sets a map view that mostly contains the whole world with the maximum zoom level possible.
      // fitWorld(<fitBounds options> options?)
      this.map.fitWorld();
    },

    panTo (lat, lng) {
      // Pans the map to a given center.
      // panTo(<LatLng> latlng, <Pan options> options?)
      this.map.panTo([+lat, +lng]);
    },

    panBy (x, y) {
      // Pans the map by a given number of pixels (animated).
      // panBy(<Point> offset, <Pan options> options?)
      this.map.panBy([x, y]);
    },

    flyTo (lat, lng, zoom) {
      // Sets the view of the map (geographical center and zoom) performing a smooth pan-zoom animation.
      // flyTo(<LatLng> latlng, <Number> zoom?, <Zoom/pan options> options?)
      this.map.flyTo([+lat, +lng], zoom);
    },

    flyToBounds (bounds) {
      // Sets the view of the map with a smooth animation like flyTo, but takes a bounds parameter like fitBounds.
      // flyToBounds(<LatLngBounds> bounds, <fitBounds options> options?)
      this.map.flyToBounds(bounds);
    },

    setMaxBounds (bounds) {
      // Restricts the map view to the given bounds (see the maxBounds option).
      // setMaxBounds(<LatLngBounds> bounds)	
      this.map.setMaxBounds(bounds);
    },

    setMinZoom (zoom) {
      // Sets the lower limit for the available zoom levels (see the minZoom option).
      // setMinZoom(<Number> zoom)
      this.map.setMinZoom(zoom);
    },

    setMaxZoom (zoom) {
      // Sets the upper limit for the available zoom levels (see the maxZoom option).
      // setMaxZoom(<Number> zoom)
      this.map.setMaxZoom(zoom);
    },

    panInsideBounds (bounds) {
      // Pans the map to the closest view that would lie inside the given bounds (if it's not already), controlling the animation using the options specific, if any.
      // panInsideBounds(<LatLngBounds> bounds, <Pan options> options?)
      this.map.panInsideBounds(bounds);
    },

    panInside (lat, lng) {
      // Pans the map the minimum amount to make the latlng visible. Use padding options to fit the display to more restricted bounds. If latlng is already within the (optionally padded) display bounds, the map will not be panned.
      // panInside(<LatLng> latlng, <padding options> options?)
      this.map.panInside([+lat, +lng]);
    },

    invalidateSize (animate = true) {
      // Checks if the map container size changed and updates the map if so — call it after you've changed the map size dynamically, also animating pan by default.
      // invalidateSize(<Boolean> animate)
      this.map.invalidateSize(animate);
    },

    stop () {
      // Stops the currently running panTo or flyTo animation, if any.
      // stop()      
      this.map.stop();
    },

    openPopup (lat, lng, content, options = {}) {
      L.popup(options).setLatLng([+lat, +lng]).setContent(content).openOn(this.map);
    },

    addMarker (id, lat, lng, options = {}) {
      options.id = id;
      options.latitude = lat;
      options.longitude = lng;
      this.addMarker(options);
    },

    openMarkerPopup (id) {
      var marker = this.findMarker(id);
      if (marker) marker.openPopup();
    },

    closeMarkerPopup (id) {
      var marker = this.findMarker(id);
      if (marker) marker.closePopup();
    },

    toggleMarkerPopup (id) {
      var marker = this.findMarker(id);
      if (marker) marker.togglePopup();
    },
  },

  events: {
    zoomstart: Event,
    zoom: Event,
    zoomend: Event,
    movestart: Event,
    move: Event,
    moveend: Event,
    popupopen: Event,
    popupclose: Event,
    tooltipopen: Event,
    tooltipclose: Event,
    mapclick: Event,
    mapdblclick: Event,
    markerclick: Event,
    markerdblclick: Event,
    markermove: Event,
  },

  init () {
    if (!document.getElementById('leaflet-styles')) {
      const css = document.createElement('style');
      css.id = 'leaflet-styles';
      css.textContent = `
        .leaflet-marker-icon-pink {
          filter: hue-rotate(120deg);
        }
        .leaflet-marker-icon-darkblue {
          filter: hue-rotate(30deg);
        }
        .leaflet-marker-icon-lila {
          filter: hue-rotate(50deg);
        }
        .leaflet-marker-icon-magenta {
          filter: hue-rotate(90deg);
        }
        .leaflet-marker-icon-red {
          filter: hue-rotate(140deg);
        }
        .leaflet-marker-icon-orange {
          filter: hue-rotate(160deg);
        }
        .leaflet-marker-icon-brown {
          filter: hue-rotate(190deg);
        }
        .leaflet-marker-icon-green {
          filter: hue-rotate(260deg);
        }
        .leaflet-marker-icon-mint {
          filter: hue-rotate(320deg);
        }
      `;
      document.head.appendChild(css);
    }
  },

  render (node) {
    this.$parse(node);

    node.textContent = '';

    this.map = L.map(node, {
      preferCanvas: this.props.preferCanvas,
      attributionControl: this.props.attributionControl,
      zoomControl: false,
      center: [this.props.latitude, this.props.longitude],
      zoom: this.props.zoom,
      keyboard: this.props.keyboard,
      scrollWheelZoom: this._toBooleanOrString(this.props.scrollWheelZoom, ['center']),
      doubleClickZoom: this._toBooleanOrString(this.props.doubleClickZoom, ['center']),
      touchZoom: this._toBooleanOrString(this.props.touchZoom, ['center']),
    });

    if (node.id) {
      this.map.id = node.id;
    }

    this.baseLayers = {};
    this.overlays = {};
    this.markers = [];

    if (this.props.tileProvider == 'custom') {
      var options = {};
      if (this.props.tileMinZoom) options.minZoom = this.props.tileMinZoom;
      if (this.props.tileMaxZoom) options.maxZoom = this.props.tileMaxZoom;
      if (this.props.tileSubdomains) options.subdomains = this.props.tileSubdomains;
      if (this.props.tileAttribution) options.attribution = this.props.tileAttribution;
      L.tileLayer(this.props.tileUrl, options).addTo(this.map);
    } else {
      var tileProvider = LEAFLET_PROVIDERS.get(this.props.tileProvider) || LEAFLET_PROVIDERS.get('OpenStreetMap');
      this._tileLayer = L.tileLayer(tileProvider.urlTemplate, tileProvider).addTo(this.map);
    }

    this.children.forEach(function (child) {
      if (child instanceof dmx.Component('leaflet-marker')) {
        this.markers.push(child._marker);
        this.addMarkerToMap(child._marker, child.props.group);
      }
    }, this);

    if (this.props.layerControl) {
      this.layerControl = L.control.layers(this.baseLayers, this.overlays).addTo(this.map);
    }

    if (this.props.zoomControl) {
      this.zoomControl = L.control.zoom({ position: this.props.zoomControlPosition }).addTo(this.map);
    }

    if (this.props.scaleControl) {
      this.scaleControl = L.control.scale({
          position: this.props.scaleControlPosition,
          metric: this.props.scaleControlMetric,
          imperial: this.props.scaleControlImperial,
        }).addTo(this.map);
    }

    this.map.on('zoomlevelschange', this.onEvent.bind(this));
    this.map.on('zoomstart', this.onEvent.bind(this));
    this.map.on('zoom', this.onEvent.bind(this));
    this.map.on('zoomend', this.onEvent.bind(this));
    this.map.on('movestart', this.onEvent.bind(this));
    this.map.on('move', this.onEvent.bind(this));
    this.map.on('moveend', this.onEvent.bind(this));

    this.map.on('click', this.onMouseEvent.bind(this, 'map'));
    this.map.on('dblclick', this.onMouseEvent.bind(this, 'map'));

    this._updateData();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('latitude') || updatedProps.has('longitude')) {
      this.map.setView([this.props.latitude, this.props.longitude], this.props.zoom, { animate: false });
    }

    if (updatedProps.has('zoom')) {
      this.map.setZoom(this.props.zoom, { animate: false });
    }

    if (updatedProps.has('zoomControl')) {
      if (this.zoomControl) {
        this.zoomControl.remove();
        this.zoomControl = null;
      }

      if (this.props.zoomControl) {
        this.zoomControl = L.control.zoom({
          position: this.props.zoomControlPosition,
        }).addTo(this.map);
      }
    } else if (this.zoomControl && updatedProps.has('zoomControlPosition')) {
      this.zoomControl.setPosition(this.props.zoomControlPosition);
    }

    if (updatedProps.has('scaleControl') || updatedProps.has('scaleControlMetric') || updatedProps.has('scaleControlImperial')) {
      if (this.scaleControl) {
        this.scaleControl.remove();
        this.scaleControl = null;
      }

      if (this.props.scaleControl) {
        this.scaleControl = L.control.scale({
          position: this.props.scaleControlPosition,
          metric: this.props.scaleControlMetric,
          imperial: this.props.scaleControlImperial,
        }).addTo(this.map);
      }
    } else if (this.scaleControl && updatedProps.has('scaleControlPosition')) {
      this.scaleControl.setPosition(this.props.scaleControlPosition);
    }

    if (updatedProps.has('markers')) {
      this.markers = this.markers.filter(marker => {
        if (!marker.static) {
          marker.remove().off();
          return false;
        }

        return true;
      });

      if (Array.isArray(this.props.markers)) {
        this.props.markers.forEach(marker => {
          var scope = new dmx.DataScope(marker, this);

          this.addMarker({
            id: dmx.parse(this.props.markerId, scope),
            latitude: +dmx.parse(this.props.markerLatitude, scope),
            longitude: +dmx.parse(this.props.markerLongitude, scope),
            tooltip: dmx.parse(this.props.markerTooltip, scope),
            popup: dmx.parse(this.props.markerPopup, scope),
            group: dmx.parse(this.props.markerGroup, scope),
            draggable: !!dmx.parse(this.props.markerDraggable, scope),
            iconUrl: dmx.parse(this.props.markerIconUrl, scope),
            color: dmx.parse(this.props.markerColor, scope),
          });
        });
      }
    }

    if (updatedProps.has('tileProvider')) {
      if (this._tileLayer) {
        this._tileLayer.remove();
        this._tileLayer = null;
      }

      var tileProvider = LEAFLET_PROVIDERS.get(this.props.tileProvider) || LEAFLET_PROVIDERS.get('OpenStreetMap');
      this._tileLayer = L.tileLayer(tileProvider.urlTemplate, tileProvider).addTo(this.map);
    }
  },

  findMarker (id) {
    return this.markers.find(function (marker) {
      return marker.id == id;
    });
  },

  addMarkerToMap (marker, group) {
    marker.remove();

    if (group) {
      if (!this.overlays[group]) {
        if (this.props.enableClustering) {
          this.overlays[group] = L.markerClusterGroup().addTo(this.map);
        } else {
          this.overlays[group] = L.layerGroup().addTo(this.map);
        }

        if (this.layerControl) {
          this.layerControl.addOverlay(this.overlays[group], group);
        }
      }
      
      marker.addTo(this.overlays[group]);
    } else {
      if (this.props.enableClustering) {
        this._markerCluster = this._markerCluster || L.markerClusterGroup().addTo(this.map);
        this._markerCluster.addLayer(marker);
      } else {
        marker.addTo(this.map);
      }
    }
  },

  addMarker (options) {
    const markerOptions = {};
    if (options.draggable) {
      markerOptions.draggable = true;
    }

    if (options.iconUrl) {
      markerOptions.icon = new L.Icon({ iconUrl: options.iconUrl });
    } else {
      markerOptions.icon = new L.Icon.Default();
      if (options.color) {
        markerOptions.icon.options.className = 'leaflet-marker-icon-' + this.props.color;
      }
    }

    var marker = L.marker([options.latitude, options.longitude], markerOptions);

    if (options.static) {
      marker.static = true;
    }

    if (options.id) {
      marker.id = options.id;
    }

    if (options.tooltip) {
      marker.bindTooltip(options.tooltip);
    }

    if (options.popup) {
      marker.bindPopup(options.popup);
    }

    marker.on('click', this.onMouseEvent.bind(this, 'marker'));
    marker.on('dblclick', this.onMouseEvent.bind(this, 'marker'));
    marker.on('move', this.onMoveEvent.bind(this, 'marker'));

    this.markers.push(marker);

    this.addMarkerToMap(marker, options.group);

    return marker;
  },

  _updateData () {
    var center = this.map.getCenter();
    var zoom = this.map.getZoom();

    this.set({
      latitude: center.lat,
      longitude: center.lng,
      zoom: zoom,
    });
  },

  _toBooleanOrString (o, allowed) {
    if (allowed && allowed.includes(o)) {
      return o;
    }

    return o && o != 'false' && o != '0';
  },

  onEvent (e) {
    this._updateData();
    this.dispatchEvent(e.type, null, this.data);
  },

  onMoveEvent (prefix, e) {
    this.dispatchEvent(prefix + e.type, null, {
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      oldLatitude: e.oldLatLng.lat,
      oldLongitude: e.oldLatLng.lng,
    });
  },

  onMouseEvent (prefix, e) {
    this.dispatchEvent(prefix + e.type, null, {
      id: e.target.id,
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      altKey: e.originalEvent.altKey,
      ctrlKey: e.originalEvent.ctrlKey,
      metaKey: e.originalEvent.metaKey,
      shiftKey: e.originalEvent.shiftKey,
      pageX: e.originalEvent.pageX,
      pageY: e.originalEvent.pageY,
      x: e.originalEvent.x || e.originalEvent.clientX,
      y: e.originalEvent.y || e.originalEvent.clientY,
    });
  },

});
